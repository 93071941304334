body{
	padding:0px;
	margin:0px;
	font-family:'FUTURA_LIGHT';
	font-size:15px;
}
#ribbon{
	background-color:#41C5D8;
	width:100%;
	height:10px;
}

#buttonbar{
	float: left;
	margin-top: 4px;
}

#buttonbar a{
	background-color: #F59100;
	text-decoration: none;
	color: white;
	font-size: 20px;
	padding: 5px;	
}

#buttonbar a:hover{
	background-color: orange;
}

.blue{color: #41C5D8;}
.green{color:#5DC577;}			
.green2{color:#98D46A;}
.green3{color:#88C205;}
.green4{color:#9DBB00;}
.green5{color:#91C700;}
.yellow{color:#B3B100;}
.yellow2{color:#C6A900;}
.orange{color:#D4A200;}
.orange2{color:#EB9600;}

#header{
	font-size:87px;
	text-align:center;
	margin:30px 0px 30px 0px;				
}		

#header img{
	vertical-align:middle;
	padding-right:30px;
}

#subheader{
	color:#B3B3B3;
	font-size:36px;
	line-heigth:70px;
	text-align:center;
	margin-bottom:10px;
}

.appstore{
	text-align:center;				
	margin-bottom:10px;				
}

a img{
	border:0px;
}

#spinner{								
	margin-bottom:30px;
	text-align:center;
	width:100%;
	overflow:hidden;
}

#quotes{					
	width:85%;	
	margin-bottom:15px;	
	margin-top:20px;
	margin-left:auto;
	margin-right:auto;
	min-width:320px;
}

#quote1, #quote2, #quote3{
	font-size:24px;
	line-height:39px;				
	color:#ffffff;				
	float:left;	
	min-width:320px;	
	width:60%;		
}

#quote2{
	float:right;	
	margin-top:20px;			
}

#quote3{
	clear:both;
	margin:auto;
	float:none;				
	padding-top:40px;
}
			
#quote1 span{background-color:#42C5D9;}						
#quote2 span{background-color:#F59100;}						
#quote3 span{background-color:#91C700;}						

#features{				

	color:#B3B3B3;
	margin-top:20px;
	overflow:hidden;
	padding-left:25px;			
}			

.col1, .col2{
	margin-right:20px;
	height:400px;				
}

.col1, .col2, .col3{
	float:left;
	width:32%;
	min-width:290px;				
}

/* 2 columns */
@media screen and (max-width: 1023px) and (min-width: 751px) {
	.col1, .col2, .col3{
		margin-left: 10%;
		margin-right: 0px;
	}	
	.col3{
		margin-top: 27px;
	}
}

/* skinny two column */
@media screen and (max-width: 750px) and (min-width: 644px) {
	.col1, .col2, .col3{
		margin-left:15px;
		margin-right: 0px;	
	}
}

/* 1 column */
@media screen and (max-width: 643px) {
	.col1, .col2, .col3{
		margin-left: 10%;		
	}	
	.col2{
		margin-top:30px;
		height:378px;
	}
}

.sectionhead{
	font-size:29px;
	font-family:'Futura_st_heavy';
	font-weight:bold;
}

.sectionheadsmall{
	font-weight:normal;
	font-family:'FUTURA_LIGHT';
}			

.col1 li, .col1 .sectionhead{
	color:#42C5D9;
}

.col2 li, .col2 .sectionhead{
	color:#91C700;
}

.col3 li, .col3 .sectionhead{
	color:#F59100;
}

ul{
	margin-top:0px;
	padding-left:16px
}

li span{
	color:#686565;
	line-height:26px;
}

#footer{
	clear:both;
	background-color:#F59100;
	height:100px;				
	margin-top:30px;
}

#footer img{
	margin-left: 60px;
	margin-top: 30px;
}			
			
.sectionhead:last-of-type{
	margin-top:30px;
}						

#wrapper{																						
	margin:auto;
	width:100%;
	height:500px;								
	position:relative;
	overflow:hidden;
}	

#spinner{
	display:none;
}
